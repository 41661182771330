// React
import React, { useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Components
import Ticker from 'components/ticker';
import PromotionalProductInner from './promotionalProductInner.component';

const PromotionalProductComponent = ({
    leaveScrollingMode,
    pageContext,
    product,
    index,
    currentIndex,
    location,
}) => {
    // Get theme
    const theme = s.themeColor(product.theme);

    // Get index based states
    const [state, setState] = useState({
        active: currentIndex === index,
        closest: currentIndex + 1 === index || currentIndex - 1 === index,
        previous: currentIndex > index,
        next: currentIndex < index,
    });
    useEffect(() => {
        setState({
            active: currentIndex === index,
            closest: currentIndex + 1 === index || currentIndex - 1 === index,
            previous: currentIndex > index,
            next: currentIndex < index,
        });
    }, [currentIndex]);

    return (
        <>
            <PromotionalProduct {...{ theme, state }}>
                <Inner {...{ state }}>
                    <PromotionalProductInner
                        {...{
                            leaveScrollingMode,
                            product,
                            pageContext,
                            location,
                            state,
                        }}
                    />
                </Inner>
                <Ticker
                    {...{
                        text: product.ticker,
                        theme,
                        locale: pageContext.locale,
                    }}
                />
            </PromotionalProduct>
        </>
    );
};

const PromotionalProduct = styled.section`
    ${props => s.layout.fullWidthBackground(props.theme.default())};
    color: ${props => props.theme.text()};

    position: absolute;
    display: flex;

    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100%;

    will-change: transform;
    transform: translateY(100%);
    transition: transform 0.7s ${s.easings.smooth.out};

    visibility: hidden;
    overflow: hidden;

    /* active */
    ${props =>
        props.state.active &&
        css`
            visibility: visible;
            transform: translateY(0%);
        `}

    /* closest */
    ${props =>
        props.state.closest &&
        css`
            visibility: visible;
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateY(-100%);
        `}
`;

const Inner = styled.div`
    ${s.grid.contentWidth()};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${s.responsive.property('padding-top', {
        0: 50,
        20: 100,
    })};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform;
    transform: translateY(-100%);
    transition: transform 0.7s ${s.easings.smooth.out};

    /* active */
    ${props =>
        props.state.active &&
        css`
            transform: translateY(0%);
        `}

    /* previous */
    ${props =>
        props.state.previous &&
        css`
            transform: translateY(100%);
        `}
`;

export default PromotionalProductComponent;
