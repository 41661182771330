// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import s from 'src/styles';

// Utilities
import { localize } from 'utilities';
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import PromotionalProducts from 'components/_landing/promotionalProducts';
// import ShopQuickLink from 'components/_landing/shopQuickLink';
import Subscription from 'components/subscription';
import Newsletter from 'components/newsletter';

const LandingPageComponent = props => {
    const {
        parentSlugs,
        content,
        content: { seoMetaTags },
        location,
        pageContext,
        pageContext: { initialTheme, locale },
    } = props;

    // Set theme
    const theme = useTheme(pageContext.initialTheme, [initialTheme]);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />

                <PromotionalProducts
                    {...{
                        pageContext,
                        content,
                        location,
                        parentSlugs,
                        theme,
                    }}
                />
                {/* <ShopQuickLink {...{ pageContext, location }} /> */}
                <Subscription {...{ pageContext, location }} />
                <Newsletter {...{ pageContext, location }} />
            </s.layout.PageContainer>
        </>
    );
};

const NamedLandingPageComponent = props => {
    const { locale } = props.pageContext;
    const {
        allDatoCmsPageHome,
        datoCmsPageStory,
        datoCmsPageCourse,
        datoCmsPageGuide,
        datoCmsPageShop,
    } = useStaticQuery(graphql`
        query {
            datoCmsPageShop {
                slug
            }
            datoCmsPageStory {
                slug
            }
            datoCmsPageCourse {
                slug
            }
            datoCmsPageGuide {
                slug
            }
            allDatoCmsPageHome {
                edges {
                    node {
                        locale
                        seoMetaTags {
                            ...SEO
                        }
                        selectedCover {
                            callToActionLink {
                                ... on DatoCmsBlogPost {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageLearn {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageCourse {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageGuide {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageShop {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsCourse {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageSustainability {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageAbout {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageContact {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPageStory {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsEspresso {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsFilterCoffee {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                                ... on DatoCmsPresaleProduct {
                                    slug
                                    model {
                                        apiKey
                                    }
                                }
                            }
                            callToActionText
                            colorTheme {
                                ...Theme
                            }
                            landingPageHeaderTextColor
                            title
                            subtitle
                            locale
                            triangleHeight
                            media {
                                fluid(maxWidth: 1600, imgixParams: { q: 30 }) {
                                    ...GatsbyDatoCmsFluid_noBase64
                                }
                                isImage
                                url
                            }
                            product {
                                ... on DatoCmsFilterCoffee {
                                    ...ProductFilterCoffeeFragment
                                }
                                ... on DatoCmsEspresso {
                                    ...ProductEspressoFragment
                                }
                            }
                        }
                        ticker
                        promotionalProducts {
                            ... on DatoCmsFilterCoffee {
                                ...ProductFilterCoffeeFragment
                            }
                            ... on DatoCmsEspresso {
                                ...ProductEspressoFragment
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <LandingPageComponent
            {...{
                content: localize(allDatoCmsPageHome, locale),
                parentSlugs: {
                    blog_post: datoCmsPageStory,
                    course: datoCmsPageCourse,
                    brew_guide: datoCmsPageGuide,
                    shop: datoCmsPageShop,
                },
                ...props,
            }}
        />
    );
};

export default NamedLandingPageComponent;
