// React
import React from 'react';

// Utilities

// Components
import PromotionalProducts from './promotionalProducts.component';

const PromotionalProductsWrapperComponent = props => {
    return <PromotionalProducts {...props} />;
};

export default PromotionalProductsWrapperComponent;
